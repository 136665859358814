//NewPerfilPerm
import { Component, Vue } from 'vue-property-decorator'

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'

//molds
import { Form } from '@/components/wrappers/molds/Form';
import { PermissionProfile } from '@/store/modules/permission/permission';

//classes
import { Alert } from '@/services/notification';

//Vuex
const namespace = 'permission';
import { Action, Getter } from 'vuex-class';

//wrappers
import {
	WForm, 
	Container, 
	Box, 
  WButton,
  WHr,
	WSubtitle,
	WTable,
	Loader,
	WColumn,
	WColumns,
	SizedBox,
} from '@/components/wrappers';

@Component({
	components: {
		WHr,
		Box,
		WForm,
		WButton,
		WSubtitle,
		Container,
		PrivateContent,
		WTable,
		Loader,
		WColumn,
		WColumns,
		SizedBox
	}
})
export default class NewPerfilPerm extends Vue { 
	
	@Action('createPermissionProfile', {namespace}) createPermissionProfile: any 
	@Action('resetNewPrermissionProfile', {namespace}) resetNewPrermissionProfile: any 
	@Getter('newPermissionProfile', {namespace}) newPermissionProfile!: PermissionProfile 
	@Getter('loader', { namespace }) isLoading!: boolean;
	@Getter('error', {namespace}) error!: any 

	alert = new Alert(this.$store)

	mounted(): void {
		document.title = "Criar Perfil de Permissão"
		this.resetNewPrermissionProfile()
	}

	async dispatchForm(permProfile: PermissionProfile): Promise<void> {
		
		await this.createPermissionProfile(permProfile)
		
		if(this.error){
			this.alert.apiMessage("Não foi possível criar perfil de permissão.", this.error, "danger")
		}else{
			this.alert.success("Perfil Criado com sucesso")
		}
	}

	goToUpdatePerm(): void {
		this.$router.push(`perm-profile/${this.newPermissionProfile.idPermission}`)
	}

	get verifyUpdate(): boolean {
		if(this.newPermissionProfile && this.newPermissionProfile.name){
			return true
		} return false
	}

	get form(): Form {
		return {
			flex: false,
			indent: true,
			grid: [
				[
					{
						fieldName: "name",
						type: "text",
						label: "Nome",
						placeholder:"Nome",
						maxlength: 50,
						required: true
					},
				],
				[
					{
						fieldName: "status",
						label: "Status",
						type: "select",
						maxlength: 11,
						value: "1",
						options:[
							{
								label: "Desativado",
								value: "0"
							},
							{
								label: "Ativo",
								value: "1"
							},
						]
					},
				],
			]
		}
	}
}

